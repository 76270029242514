@use "mixins/breakpoint";
@use "variables/color";

.productGrid {
  position: relative;
  --product-min-width: 136px;
  display: grid;
  row-gap: 32px;
  column-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(var(--product-min-width), 1fr));
  justify-content: center;
  margin: 32px 0;

  @include breakpoint.tablet {
    --product-min-width: 220px;
  }

  @include breakpoint.desktop {
    --product-min-width: 200px;
    margin: 40px 0 48px;
    column-gap: 24px;
  }
}

.loading {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 2;

  &__dark {
    background-color: color.$darkViolet;
    opacity: 0.75;
  }
}
