@use "variables/color";

.pagination {
  --pagination-item-bg-color: #0000;
  --pagination-item-color: #{color.$black};
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  justify-content: center;

  &__darkMode {
    --pagination-item-bg-color: #{color.$darkViolet};
    --pagination-item-color: #{color.$white};
  }
}
