@use "variables/color";

.paginationItem {
  align-items: center;
  background-color: var(--pagination-item-bg-color);
  border-radius: 8px;
  color: var(--pagination-item-color);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: center;
  min-width: 32px;
  text-decoration: none;

  &__nonInteractive {
    cursor: default;
  }

  &__active {
    --pagination-item-bg-color: #{color.$primaryBlue};
    --pagination-item-color: #{color.$white};
  }
}
