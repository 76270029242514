@use "variables/color";
@use "mixins/breakpoint";

.collection {
  padding: 1px 0;
  --screen-sections-gap: 16px;

  @include breakpoint.tablet {
    --screen-sections-gap: 32px;
  }

  &__darkMode {
    background-color: color.$almostBlack;
    color: color.$white;
  }
}

.title {
  text-align: center;
  margin: var(--screen-sections-gap) auto;
}

.pagination {
  margin: var(--screen-sections-gap) auto;
}

.empty {
  text-align: center;
  margin: var(--screen-sections-gap) auto;
}
